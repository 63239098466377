.SolarPanel {
  color: #F7FF46;
}

.SolarPanel-sunOff {
  fill: #FFF;
}

.SolarPanel-sunOn {
  fill: #F7FF46;
}

.SolarPanel-sunSpin {
  animation: 10s linear 0s infinite rotate;
  transform-origin: 24px 15px;
}

.SolarPanel-stripe1 {
  animation: 3s alternate 0s infinite flicker;
}

.SolarPanel-stripe2 {
  animation: 3s alternate 1s infinite flicker;
}

@keyframes flicker {
  0%, 100% {
    fill: #FFF
  }
  50% {
    fill: #F7FF46
  }
}

@keyframes rotate {
  from { 
      transform: rotate(0deg); 
  }
  to { 
      transform: rotate(360deg); 
  }
}