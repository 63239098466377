.menu {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(-100vw, 0, 0);
    z-index: 10;
    width: 256px;
    height: 100vh;
}

.menu-show {
    transform: translate3d(0vw, 0, 0);
    transition: transform .5s cubic-bezier(0, .52, 0, 1);
}
