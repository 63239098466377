.stripes--red {
  background: linear-gradient(to right, #F86A69 2px, #fff 2px, #fff 4px);
  background-size: 4px;
  background-repeat: repeat;
  max-width: 100%;
}

.stripes--blue {
  background: linear-gradient(to right, #17242a 2px, #fff 2px, #fff 4px);
  background-size: 4px;
  background-repeat: repeat;
  max-width: 100%;
}