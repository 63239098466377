.Gas--on {
  animation: 5s alternate 0s infinite light;
}

@keyframes light {
  0%, 100% {
    fill: #FFF
  }
  50% {
    fill: #fe6572
  }
}
