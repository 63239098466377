.leaderboard--hidden {
    display: none;
}

.leaderboard--visible {
    display: block;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

.leaderboard--user {
    color: #65fef0;
}
