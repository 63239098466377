@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

main {
  min-height: 100vh;
}

.intro-background {
  background: linear-gradient(135deg, #17242a, #17242a 70%, #FFF 70%, #FFF);
}

@media(min-width: 1024px) {
  .intro-background {
    background: linear-gradient(to bottom right, #17242a, #17242a 70%, #FFF 70%, #FFF);
  }
}

@media(min-width: 1280px) {
  .intro-background {
    background: linear-gradient(to bottom right, #17242a, #17242a 60%, #FFF 60%, #FFF);
  }
}

@media(min-width: 768px) {
  .mixer-background {
    background: linear-gradient(to bottom, #ffffff, #ffffff 677px, #e0fffc 677px, #e0fffc);
  }
}
