.accordion--section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  text-align: start;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover,
.accordion--active {
  background-color: #ccc;
}

.accordion--title {
  font-weight: 600;
  font-size: 14px;
}

.accordion--icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.accordion--icon--rotate {
  transform: rotate(90deg);
}

.accordion--content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion--text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
