.LightMap {
    width: auto;
    max-width: 100%;
}

.bulb--on g path:first-of-type {
    fill: #F7FF46;
}

.bulb--off g path:first-of-type {
    fill-opacity: 0.5;
    fill: #FFFFFF;
}
