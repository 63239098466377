.blades {
    animation: 4s linear 0s infinite rotate;
    transform-origin: 40px 25px;
}

.blades--on {
    animation-play-state: running;
}

.blades--off {
    animation-play-state: paused;
}

@keyframes rotate {
    from { 
        transform: rotate(0deg); 
    }
    to { 
        transform: rotate(360deg); 
    }
}
