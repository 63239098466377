.NuclearAtom-source {
  transform-origin: 47px 50px;
  animation: 2s linear 0s infinite pulse;
}

.NuclearAtom-source--on {
  animation-play-state: running;
}

.NuclearAtom-source--off {
  animation-play-state: paused;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
}