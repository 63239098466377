.parallelogram {
    margin-top: -2rem;
    margin-bottom: -0.5rem;
    transform: skewX(-10deg);
}

.parallelogram-content {
    margin-left: 1rem;
    padding-right: 2rem;
    transform: skewX(10deg);
}

.upload-button {
    margin: auto 1rem auto 0;
}

.leaderboard-button {
    margin: auto 1rem auto 0;
}
